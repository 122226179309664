<template>
  <div class="invitation_code">
    <el-card class="invitation_code_card special_card">
      <el-form v-model="queryInfo">
        <el-form-item label="渠道方：">
          <el-radio-group v-model="queryInfo.channelName" @change="typeChange">
            <el-radio-button label>不限</el-radio-button>
            <el-radio-button
              v-for="(item, index) in options"
              :key="index"
              :label="item.channelName"
            ></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="时效性：">
          <el-radio-group
            v-model="queryInfo.timelinessEnum"
            @change="typeChange"
          >
            <el-radio-button
              v-for="(item, index) in optionsTime"
              :key="index"
              :label="item.time"
              >{{ item.timeName }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="请输入专题名称"
            v-model="queryInfo.bookName"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="search()"
              >搜索</el-button
            >
          </el-input>
          <el-button type="primary" class="add_special" @click="addClickInvita"
            >+新建</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <div class="invitaiton_item">
      <div
        class="invitaiton_item_li"
        v-for="(item, index) in invitationList"
        :key="index"
      >
        <div class="invitaiton_item_li_left">
          <div class="invitaiton_item_li_left_img">
            <span v-if="!item.isFailure">失效</span>
            <img :src="item.bookImage" alt="" />
          </div>
          <div class="invitaiton_item_li_left_flex">
            <div class="invitaiton_item_li_left_flex_p">
              <p>
                <span>关联书籍</span>
                {{ item.bookName }}
              </p>
            </div>
            <div class="invitaiton_item_li_left_flex_p">
              <p>
                <span>时效日期</span>
                {{ item.endTime }}
              </p>
            </div>
            <div class="invitaiton_item_li_left_flex_p curomnt">
              <p>
                <span @click="informationClick(item)">券码数量</span>
                <span @click="informationClick(item)" class="item">{{
                  item.totalNumberCode
                }}</span>
                <span @click="informationClick(item)" style="margin-left: 10px"
                  >已核销</span
                >
                <em @click="informationClick(item)">{{
                  item.afterNumberCode
                }}</em>
              </p>
            </div>
          </div>
        </div>
        <div class="invitaiton_item_li_right">
          <div class="invitaiton_item_li_right_p">
            创建时间:{{ item.createTime }}
          </div>
          <div class="invitaiton_item_li_right_p">
            创建人:{{ item.createName }}
          </div>
          <div class="invitaiton_item_li_right_text">
            <el-button type="text" @click="editClick(item)">编辑</el-button>
            <el-button type="text" @click="deleteClick(item)">删除</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="special_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[30, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {
  channerAll,
  invitationListAll,
  deleteInvitation,
} from "@/api/invitation.js";
import { pagination } from "@/mixins/pagination";
const defaultQueryInfo = Object.freeze({
  channelName: "", //渠道商名称
  pageNumber: 0, // 页码
  pageSize: 30, //每页显示个数
  bookName: "", //书籍名称
  timelinessEnum: "ALL", //时效性ALL,NOT_AT_THE,HAS_ENDED
});
export default {
  name: "invitationCode",
  mixins: [pagination],
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      options: [], //渠道商名称
      optionsTime: [
        {
          timeName: "不限",
          time: "ALL",
        },
        {
          timeName: "未失效",
          time: "NOT_AT_THE",
        },
        {
          timeName: "已失效",
          time: "HAS_ENDED",
        },
      ], //时效性
      invitationList: [], //表格数据--->邀请码列表
    };
  },
  created() {
    this.getChanner(); //获取所有的渠道商名称
    this.search();
  },
  methods: {
    informationClick(row) {
      this.$router.push({
        path: "/operation/information",
        query: {
          id: row.id,
        },
      });
    },
    //删除邀请码
    deleteClick(row) {
      console.log(row);
      this.$confirm(`确认删除${row.bookName}吗 ?`, {
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await deleteInvitation({ id: row.id });

          if (res.success) {
            this.$messageSuccess(`${row.bookName}删除成功`);
            this.search();
          }
        })
        .catch(() => {});
    },
    //编辑
    editClick(row) {
      this.$router.push({
        path: "/operation/invitation/edit",
        query: {
          id: row.id,
        },
      });
    },
    //新增
    addClickInvita() {
      //新增
      this.$router.push({
        path: "/operation/invitation/add",
      });
    },
    //选择
    typeChange() {
      this.search();
    },
    //表格数据查询
    async search() {
      const { data: res } = await invitationListAll(this.queryInfo);
      if (res.code === 10200) {
        this.invitationList = res.data.data;
        this.total = res.data.pageTotal;
      }
    },
    //获取所有的渠道商名称
    async getChanner() {
      const { data: res } = await channerAll();
      if (res.code === 10200) {
        this.options = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.invitation_code {
  overflow: auto;
  .special_card {
    /deep/ .is-active .el-radio-button__inner:hover {
      color: #fff !important;
    }
  }
  .invitaiton_item {
    width: 100%;
    margin-bottom: 40px;
    overflow: hidden;

    .invitaiton_item_li {
      width: 100%;
      margin-top: 16px;
      overflow: hidden;
      box-sizing: border-box;
      padding: 15px 24px;
      display: flex;
      background-color: #fff;

      .invitaiton_item_li_left {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;

        .invitaiton_item_li_left_img {
          width: 100%;
          overflow: hidden;
          width: 120px;
          height: 120px;
          position: relative;
          margin-right: 15px;

          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 8;
            border-radius: 2px;
          }

          span {
            display: block;
            width: 100%;
            height: 20px;
            line-height: 20px;
            text-align: center;
            color: #fff;
            font-weight: 400;
            font-size: 12px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 99;
            background-color: #b9b8b8;
          }
        }

        .invitaiton_item_li_left_flex {
          flex: 1;
          overflow: hidden;

          .curomnt {
            span {
              cursor: pointer;
            }
            em {
              cursor: pointer;
            }
          }

          .invitaiton_item_li_left_flex_p {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            margin-bottom: 12px;

            p {
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              font-size: 16px;
              font-weight: 500;
              color: #151515;
            }

            span {
              color: #8d8d8d;
              font-size: 14px;
              font-weight: 400;
              margin-right: 10px;
            }

            .item {
              font-size: 22px;
            }

            em {
              font-size: 22px;
              font-weight: 500;
              color: #4aa9ff;
              font-style: normal;
            }
          }
        }
      }

      .invitaiton_item_li_right {
        width: 250px;
        overflow: hidden;

        .invitaiton_item_li_right_p {
          font-size: 12px;
          font-weight: 400;
          color: #c0c0c0;
          white-space: nowrap;
          overflow: hidden;
          margin-bottom: 12px;
        }

        /deep/ .el-button--text {
          color: #999999;
        }
      }
    }
  }
  .invitation_code_card {
    /deep/ .el-radio-button__inner {
      border: none;
      border-radius: 4px !important;
      margin-right: 8px;
    }

    /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: #8483d0 !important;
      box-shadow: -1px 0 0 0 #8483d0;
    }

    /deep/ .el-radio-button__inner:hover {
      color: #8483d0;
    }

    /deep/ .el-input-group {
      width: 415px;
    }

    /deep/ .el-input__inner {
      border: 1px solid #8483d0;
    }

    /deep/ .seach {
      border: 1px solid #8483d0;
      border-radius: 0;
      color: #fff;
      background-color: #8483d0;
    }
    .add_special {
      margin-left: 50px;
    }
  }
  .special_page {
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>