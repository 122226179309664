import request from "@/utils/request"

//查询所有的渠道商名称
export function channerAll(data) {
    return request({
        url: '/channelMassage/listName',
        method: 'get',
        params: data
    })
}

//查询一览
export function invitationListAll(data) {
    return request({
        url: '/googleAPi/listPage',
        method: 'get',
        params: data
    })
}

//查询所有的书籍名称
export function bookAll(data) {
    return request({
        url: '/books/listBookName',
        method: 'get',
        params: data
    })
}

//邀请码新增
export function invitionAdd(data) {
    return request({
        url: '/googleAPi/save',
        method: 'post',
        data
    })
}

//查看单个详情 邀请码
export function detailsIdInvitation(data) {
    return request({
        url: '/googleAPi/listByDetailsId',
        method: 'get',
        params: data
    })
}

//编辑邀请码
export function invitionEdit(data) {
    return request({
        url: '/googleAPi/update',
        method: 'put',
        data
    })
}

//下载二维码
export function downloadQRcode(data) {
    return request({
        url: '/bookQrCode/download',
        method: 'post',
        data
    })
}


//删除邀请码
export function deleteInvitation(data) {
    return request({
        url: '/googleAPi/deleteById',
        method: 'delete',
        params: data
    })
}

//核销头部
export function detailsHead(data) {
    return request({
        url: '/writtenOff/listById',
        method: 'get',
        params: data
    })
}

//分配信息根据主键id
export function writtenOffGoogleId(data) {
    return request({
        url: '/writtenOff/listByGoogleId',
        method: 'get',
        params: data
    })
}

//分配信息根据主键id
export function afterPage(data) {
    return request({
        url: '/writtenOff/listAfterPage',
        method: 'get',
        params: data
    })
}

//核销数据writListPage
export function writListPage(data) {
    return request({
        url: '/writtenOff/listPage',
        method: 'get',
        params: data
    })
}

//修改码表
export function googleAPiNumber(data) {
    return request({
        url: '/googleAPi/updateNumber',
        method: 'put',
        data
    })
}